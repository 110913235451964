<script>
import Layout from "../../layouts/main";
import DatePicker from "vue2-datepicker";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import OrdenService from "@/services/OrdenService";
import HelperJS from "@/functions/general";
import {  mapState } from "vuex";
const ordenService = new OrdenService();
import Swal from "sweetalert2";
import moment from "moment";

import 'vue2-datepicker/locale/es';
const helper = new  HelperJS();

/**
 * Invoice-list component
 */
export default {
  page: {
    title: "Intereses generados",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader,
    DatePicker },
  data() {
    return {
      title: "Intereses generados",
      filterBar:{
        currency: '',
        status: '',
        from: null,
        to: null
      },
      
      infoPage:{
        status: ['En curso', 'Por cobrar', 'Cobrado'],
        method_payments: [
          {
            id:1,
            text: 'Efectivo'
          },
          {
            id:2,
            text: 'Transferencia' 
          },
          {
            id:3,
            text: 'Cheque' 
          },
        ],
        currencys:['MXN', 'USD'],
        view: 'index',
        statesInfo: [
           {
            id:0,
            text: 'En curso'
          },
          {
            id:1,
            text: 'Por cobrar' 
          },
  
          {
            id:2,
            text: 'Cobrado' 
          }
        ]
      },
      items: [
        {
          text: "Inicio",
        },
        {
          text: "Intereses",
          active: true,
        },
      ],
      invoicelist: [
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
      
        {
          key: "account.name",
          label: "Cuenta",
          sortable: true,
        },
        {
          key: "cut_date",
          sortable: true,
          label: "Fecha de corte",
        },
        {
          key: "client.full_name",
          label: "Nombre prestamista",
          sortable: true,
        },
        {
          key: "amount",
          sortable: true,
          label: "Interés generado",
        },
        
        {
          key: "account.currency",
          label: "Moneda",
          sortable: true,
        },
        {
          key: "status",
          label: "Estado de pago",
          sortable: true,
        },
        /*{
          key: "download",
          label: "Descargar PDF",
        },*/
        {
          key: "action",
          label:  "Acción",
        },
       
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    
  ...mapState('auth', ['currentUser']),
    rows() {
      return this.invoicelist.length;
    },
  },
  

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  
  created(){

    this.chargeItems();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    formatMoney(value){
      return helper.formatMoney(value);

    },
    formatDate(value, type='short'){
      return helper.formatDate(value, type);

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    translateStatus(status){
      return this.infoPage.status[status];
    },
   bgStatus(status){
     let bgs_back = ['bg-soft-warning','bg-soft-success','bg-soft-info'];
      return bgs_back[status];
    },
    cancelPayment(id){
        ordenService.cancelPayment(id).then(async ()=>{
          
          this.successForm();
          this.chargeItems();

        });
    },
     successForm(){
      Swal.fire("¡Operación exitosa!", "El pago fué cancelado", "success");
    },
     chargeItems(){

        ordenService.getList().then(response=>{
          this.invoicelist = response.data.ordenes;
        });
          /*clientService.getList().then(response=>{
          let prestamistas = response.data.clients;
          prestamistas.map(item=> item.text = item.full_name);

          this.prestamistas = prestamistas;
        });*/

    },
    
    applyFilter(){
      let params = {...this.filterBar};
     if(params.from!==null){
        params.from = moment(params.from, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
      if(params.to!==null){        
        params.to = moment(params.to, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
     
       ordenService.getList(params).then(response=>{
          this.invoicelist = response.data.ordenes;
        });

    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-4">
    
      </div>
      <div class="col-md-8">
        <div class="row">

          <div class="col-md-3">
            
              <select class="form-control select2" v-model="filterBar.currency">
              <option value="">Todos las monedas</option>
                <option :value="currency" v-for="currency in infoPage.currencys" :key="currency" v-text="currency"></option>
              </select>
            </div>
              <div class="col-md-3">
            
              <select class="form-control select2" v-model="filterBar.status" aria-placeholder="">
              <option value="">Todos los intereses</option>
                <option :value="element.id" v-for="element in infoPage.statesInfo" :key="element.id" v-text="element.text"></option>
              </select>
            </div>

          <div class="col-md-6">
            <div class="float-end">
              <div class="form-inline mb-3">
                <div
                  class="input-daterange input-group"
                  data-provide="datepicker"
                  data-date-autoclose="true"
                >
                <date-picker
                              v-model="filterBar.from"
                                :first-day-of-week="1"
                                format="DD-MMM-YYYY"
                                lang="es"
                              class="form-control text-left date-custom bx-left"
                              placeholder="Fecha desde"
                              ></date-picker>
                  <date-picker
                              v-model="filterBar.to"
                                :first-day-of-week="1"
                                format="DD-MMM-YYYY"
                                lang="es"
                              class="form-control text-left  date-custom bx-right"
                              placeholder="Fecha hasta"
                              ></date-picker>
                  <div class="input-group-append ">
                    <button type="button" class="btn btn-primary btn-custom-date" @click.prevent="applyFilter()">
                      <i class="mdi mdi-filter-variant"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Mostrar&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
          <label class="d-inline-flex align-items-center fw-normal">
            Buscar:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ms-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="invoicelist"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(check)="data">
          <div class="custom-control custom-checkbox text-center">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`contacusercheck${data.item.id}`"
            />
            <label
              class="custom-control-label"
              :for="`contacusercheck${data.item.id}`"
            ></label>
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ data.item.id }}
          </a>
        </template>


         <template v-slot:cell(cut_date)="data">
          <label class="custom-control-label">
            {{ formatDate(data.item.cut_date) }}
          </label>
        </template>
        <template v-slot:cell(client.full_name)="data">
          <label class="custom-control-label">
            <a @click="routeGo('/prestamistas/show/'+data.item.client_id)"
              
                title="Ver información"
                class="a-action px-2 text-info"
            
            >{{ data.item.client.full_name }}</a>
          </label>
        </template>
        
        <template v-slot:cell(status)="data">
          <div
            class="badge rounded-pill  font-size-12"
            :class="bgStatus(data.item.status)"
          >
            {{ translateStatus(data.item.status) }}
          </div>
        </template>
        <template v-slot:cell(amount)="data">
          <label class="custom-control-label">
            {{ formatMoney(data.item.amount) }}
          </label>
        </template>

        <!--<template v-slot:cell(name)="data">
          <a href="#" class="text-body">{{ data.item.name }}</a>
        </template>
        <template v-slot:cell(download)>
          <div>
            <button class="btn btn-light btn-sm w-xs">
              Pdf
              <i class="uil uil-download-alt ms-2"></i>
            </button>
          </div>
        </template>-->
        <template v-slot:cell(action)="data">
          <div>
            <button class="btn btn-light btn-sm w-xs" v-if="(data.item.status===1)"
                        @click="routeGo('/intereses/payment/'+data.item.id)">
              Pagar
              <i class="uil uil-download-alt ms-2"></i>
            </button>
             <button class="btn btn-light btn-sm w-xs" v-if="(data.item.status===2)"
                        @click="routeGo('/intereses/info/'+data.item.id)">
              Ver pago
              <i class="uil uil-eye ms-2"></i>
            </button>
             <button class="btn btn-danger btn-sm w-xs ml-1" v-if="(data.item.status===2)"
                        @click="cancelPayment(data.item.id)">
              Cancelar pago
              <i class="uil uil-sync-exclamation ms-2"></i>
            </button>
          </div>
          <!--<ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Edit"
              >
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-danger"
                v-b-tooltip.hover
                title="Delete"
              >
                <i class="uil uil-trash-alt font-size-18"></i>
              </a>
            </li>
          </ul>-->
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
