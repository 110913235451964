
import moment from "moment";



export default class HelperJS{



    formatMoney(value){

      value = Number(value);
      value = value.toFixed(2)
      return '$'+ value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    formatDate(date, type='short'){
      let dateIS = moment(date), dateReturn ='';
      if(type==='short'){

        dateReturn = dateIS.format('DD-MMM-YYYY')
      }
      if(type==='complete'){

        dateReturn = dateIS.format('DD MMMM YYYY')
      }




      return dateReturn;
    }

}
